let shadowRoot;

window.addEventListener('heyflow-init', (event) => {
    shadowRoot = 
        (document.querySelector('heyflow-wrapper') &&
            document.querySelector('heyflow-wrapper').shadowRoot) ||
        document;
        
        console.log(shadowRoot);
});


window.addEventListener('heyflow-change', (event) => {
    console.log(event.detail.screenName);
    
    var floorAreaSelected;
    var propertyAgeSelected;
    var floorArea = 0;



    if (event.detail.screenName === "property-age" || event.detail.screenName === "property-bedroom" || event.detail.screenName === "heating-source") {
        var watts_per_sqm_multiplier = 0;


        // Get the container holding the radio buttons
        const noOfBedroomSelection = shadowRoot.querySelector("#multiple-choice-block-id-c1c15344");
        const selectedNoOfBedroom = noOfBedroomSelection.querySelector('input[type="radio"]:checked');
        
        if(selectedNoOfBedroom){
            console.log(selectedNoOfBedroom.getAttribute("data-answer"));
            floorAreaSelected = selectedNoOfBedroom.getAttribute("data-answer");
        }
        
        
        const propertyAgeSelection = shadowRoot.querySelector("#multiple-choice-block-mc-ce92355f");
        const selectedPropertyAge = propertyAgeSelection.querySelector('input[type="radio"]:checked');
        
        if(selectedPropertyAge){
            console.log(selectedPropertyAge.getAttribute("data-answer"));
            propertyAgeSelected = selectedPropertyAge.getAttribute("data-answer");
        }
        
        
        
            
        console.log("Floor Area Selected: "+floorAreaSelected);
        console.log("Property Age Selected: "+propertyAgeSelected);


        // // Fetch values directly from DOM elements
        // var floorAreaInput = shadowRoot.getElementById('input-d39257f6');
        // var propertyAgeSelect = shadowRoot.getElementById('mco-94d18274');
        
        // console.log(propertyAgeSelect);

        // // Check if elements are found before proceeding
        // // if (floorAreaInput && propertyAgeSelect) {
        //     var floorArea = parseFloat(floorAreaInput.value);
        //     var propertyAge = propertyAgeSelect.getAttribute("data-answer");
            
            
            // console.log('Floor area input:', floorArea);
            // console.log('Property age input:', propertyAge);

            // Convert floor area to square meters
            // floorArea = floorArea * 0.092903;

        switch (floorAreaSelected) {
            case "1 bedroom":
                floorArea = 45;
                break;
            case "2 bedrooms":
                floorArea = 70;
                break;
            case "3 bedrooms":
                floorArea = 90;
                break;
            case "4 bedrooms":
                floorArea = 120;
                break;
            case "5 bedrooms":
                floorArea = 200;
                break;
            case "6 bedrooms":
                floorArea = 300;
                break;
            case "7+ bedrooms":
                floorArea = 0;
                break;
            default:
                floorArea = 0;
        }



            switch (propertyAgeSelected) {
                case "1900-1979":
                    watts_per_sqm_multiplier = 100;
                    break;
                case "1980-2006":
                    watts_per_sqm_multiplier = 70;
                    break;
                case "2007-2013":
                    watts_per_sqm_multiplier = 50;
                    break;
                case "2014-2023":
                    watts_per_sqm_multiplier = 40;
                    break;
                case "2024-currently being built":
                    watts_per_sqm_multiplier = 30;
                    break;
                default:
                    watts_per_sqm_multiplier = 0;
            }
            
            
            console.log("Floor Area: "+floorArea);
            console.log("Watts per SQM Multiplier: "+watts_per_sqm_multiplier);

            var heatloss_perW_sqm = floorArea * watts_per_sqm_multiplier;
            var heatloss_kW = (heatloss_perW_sqm / 1000).toFixed(1);

            var roundedUp_kW = 0;

            var cost = 0;
            var bus_grant = 7500;

            switch (true) {
                case (heatloss_kW >= 0 && heatloss_kW <= 5):
                    console.log(heatloss_kW);
                    cost = 7500;
                    roundedUp_kW = 5;
                    break;
                case (heatloss_kW > 5 && heatloss_kW <= 8):
                    console.log(heatloss_kW);
                    cost = 7500;
                    roundedUp_kW = 8;
                    break;
                case (heatloss_kW > 8 && heatloss_kW <= 12 ):
                    console.log(heatloss_kW);
                    cost = 9160;
                    roundedUp_kW = 12;
                    break;
                case (heatloss_kW > 12 && heatloss_kW <= 16 ):
                    console.log(heatloss_kW);
                    cost = 9490;
                    roundedUp_kW = 16;
                    break;
                default:
                    console.log(heatloss_kW);
                    cost = 0;
                    roundedUp_kW = 0;
                    break;
            }

            if (cost != 0) {
                var estFinalCost = Math.abs(bus_grant - cost);

                if (cost > bus_grant) {
                    estFinalCost = cost - bus_grant;
                } else {
                    estFinalCost = 0;
                }

                var unfortunatelyContainer = shadowRoot.querySelector('#text-block-rich-text-124e37e8');
                if (unfortunatelyContainer) {
                    unfortunatelyContainer.style.display = "none";
                }

                var costBreakdownContainer = shadowRoot.querySelector('#text-block-id-caf6c420');
                if (costBreakdownContainer) {
                    costBreakdownContainer.style.display = "block";
                }

                var estimateInclusionContainer = shadowRoot.querySelector('#text-block-id-67d859cc');
                if (estimateInclusionContainer) {
                    estimateInclusionContainer.style.display = "block";
                }

                
                
                
                
                
                const calculated_kw = shadowRoot.getElementById('id-d6a7aacb');
                const calculated_roundedUpkw = shadowRoot.getElementById('id-7a8655b7');
                const calculated_cost = shadowRoot.getElementById('id-3fabb7ee');
                const calculated_finalCost = shadowRoot.getElementById('id-83e998ff');

                if (calculated_kw) {
                    calculated_kw.value = heatloss_kW.toLocaleString();
                } else {
                    console.log("Input element with data-label='kw' not found.");
                }

                if (calculated_roundedUpkw) {
                    calculated_roundedUpkw.value = roundedUp_kW.toLocaleString() + "kW";
                } else {
                    console.log("Input element with data-label='rounded up kw' not found.");
                }

                if (calculated_cost) {
                    if (cost == -1) {
                        calculated_cost.value = "Free";
                    } else {
                        calculated_cost.value = "£" + cost.toLocaleString();
                    }
                } else {
                    console.log("Input element with data-label='cost' not found.");
                }

                if (calculated_finalCost) {
                    if (cost == -1) {
                        calculated_finalCost.value = "Free";
                    } else {
                        calculated_finalCost.value = "£" + estFinalCost.toLocaleString();
                    }
                } else {
                    console.log("Input element with data-label='finalCost' not found.");
                }

            } else {
                var element = shadowRoot.querySelector("#text-block-rich-text-124e37e8");
                if (element) {
                    element.style.display = "block";
                }

                var costBreakdownContainer = shadowRoot.querySelector("#text-block-id-caf6c420");
                if (costBreakdownContainer) {
                    costBreakdownContainer.style.display = "none";
                }

                var estimateInclusionContainer = shadowRoot.querySelector("#text-block-id-67d859cc");
                if (estimateInclusionContainer) {
                    estimateInclusionContainer.style.display = "none";
                }
            }

            console.log("");
            // console.log("Year when the property was built: ", propertyAge);
            // console.log("Floor Area: ", floorArea);
            console.log("Heat loss W/SQM: ", heatloss_perW_sqm + "W");
            console.log("Heat loss kW/SQM: ", heatloss_kW + "kW");
            console.log("");

        // } else {
        //     console.log("Input elements for floor_area or property_age not found.");
        // }
    }
    
    
    
    if (event.detail.screenName === "contact-information" || event.detail.screenName === "thank-you") {
        
        // Get the container holding the radio buttons
        const radioContainer = shadowRoot.querySelector("#multiple-choice-block-id-974f59fb");

        // Get the selected radio button element
        const selectedRadio = radioContainer.querySelector('input[type="radio"]:checked');

        var selectedValue;

        // If a radio button is selected, get its value
        if (selectedRadio) {
        selectedValue = selectedRadio.dataset.answer;
        console.log("Selected value:", selectedValue);
        // You can use the selectedValue variable for further processing
        } else {
        console.log("No option selected");
        }


        // var datePickerContainer = shadowRoot.querySelector("#id-c1d8904f-container");
        var datePickerContainer = shadowRoot.querySelector("#date-picker-id-6ac32ed3");
        var timePickerContainer = shadowRoot.querySelector("#select-block-id-a2d4679a");


        // console.log(datePickerContainer.style.display);
        // console.log(timePickerContainer.style.display);

        if(selectedValue == "Scheduled Call"){

            console.log("Schedule a Call");

            if (datePickerContainer) {
                datePickerContainer.style.display = "block";
            }
            
            if (timePickerContainer) {
                timePickerContainer.style.display = "block";
            }
        }
        else{

            console.log("Do Not Schedule a Call");

            if (datePickerContainer) {
                datePickerContainer.style.display = "none";
            }
            
            if (timePickerContainer) {
                timePickerContainer.style.display = "none";
            }
        }
            
    }
    
    
    
    
});
